/* eslint-disable no-prototype-builtins */
import { push } from 'react-router-redux';
import { put } from 'redux-saga/effects';

export const getPathNameFromUrlString = (urlString: string) => {
  const [urlStringPath] = urlString.split('?');
  return urlStringPath;
};

export const extractAfterSlash = (str: string): string => {
  const slashIndex = str.indexOf('/');
  if (slashIndex === -1) {
    return '';
  }
  const questionMarkIndex = str.indexOf('?');

  return questionMarkIndex === -1 || questionMarkIndex < slashIndex
    ? str.substring(slashIndex + 1)
    : str.substring(slashIndex + 1, questionMarkIndex);
};

export function* updateUrlParams({ urlString }) {
  const pathname = extractAfterSlash(window.location.hash || '');
  if (urlString.includes(pathname)) {
    yield put(push(urlString));
  }
}
const defaultParseFilterFromUrlStr = (str: string) => {
  const [id, param] = str.split('=');
  if (id === 'page') {
    return { page: JSON.parse(decodeURIComponent(param)) };
  }
  return { id, value: JSON.parse(decodeURIComponent(param)) };
};

export const url2filters = (url: string, fn = defaultParseFilterFromUrlStr) =>
  url.split('&').map((str) => fn(str));

export const filters2url = (filters) =>
  filters
    ?.map(
      ({ id, value }) => `${id}=${encodeURIComponent(JSON.stringify(value))}`
    )
    .join('&');

const getFilterParams = (filter) => {
  const { id, value } = filter;
  if (!id || !value) {
    return {};
  }
  const paramValue = value.hasOwnProperty('value') ? value.value : value;
  return { [id]: paramValue };
};

export const filters2params = (filters, fn = getFilterParams) =>
  filters?.reduce((prev, next) => {
    return { ...prev, ...fn(next) };
  }, {});

export const initFromUrl = (searchString = '', names = [], fn) => {
  const urlParams =
    searchString && searchString[0] === '?' && searchString.indexOf('=') > 0
      ? url2filters(searchString.substr(1), fn)
      : [];
  const page = urlParams.find((param) => param.hasOwnProperty('page'));
  const orderDirection = urlParams.find(
    (param) => param.id === 'orderDirection'
  );
  const orderColumn = urlParams.find((param) => param.id === 'orderColumn');
  const sort =
    urlParams.length && orderDirection && orderColumn
      ? [orderDirection, orderColumn]
      : [];
  const filters =
    Array.isArray(names) && names.length
      ? urlParams.filter((param) => names.some((name) => name === param.id))
      : urlParams.filter((param) => !param.hasOwnProperty('page'));
  return { sort, page, filters };
};
