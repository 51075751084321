import React from 'react';

import { Box } from '@mui/material';
import { TFunction } from 'react-i18next';
import endOfDay from 'date-fns/endOfDay';

import { FilterType } from '@ibox/ui';

import {
  DOCUMENT_TYPES,
  DOCUMENT_TYPES_NAMES,
  PRODUCT_GROUPS_NAMES,
} from '../../constants';
import { LinkCell } from '../../components/link-cell';
import { ProductGroupIcon } from '../../components/product-group-icon';
import { Status } from '../../components/status';
import { CountryCode } from '../../store/reducers';
import { getPgListByCountry } from '../../utils/utils';

import css from './reports.module.scss';

export const DOC_TYPES_NAMES = {
  [DOCUMENT_TYPES.AGGREGATION]: 'Агрегация',
  [DOCUMENT_TYPES.UTILISATION]: 'Нанесение',
  [DOCUMENT_TYPES.DROPOUT]: 'Списание (отбраковка)',
};

export type ProductGroupTypes =
  | 'milk'
  | 'pharma'
  | 'alcohol'
  | 'shoes'
  | 'tobacco'
  | 'lp'
  | 'appliances'
  | 'beer'
  | 'water'
  | 'antiseptic';

export const REPORT_STATUS_NAMES = {
  DRAFT: 'Черновик',
  READY_TO_SEND: 'Готов к отправке',
  PENDING: 'Ожидание ответа',
  SENT: 'Отправлен',
  REJECTED: 'Отклонен',
};

const getReportStatusFilter = (t: TFunction<'translation'>) => [
  { value: 'DRAFT', label: t(REPORT_STATUS_NAMES.DRAFT) },
  {
    value: 'READY_TO_SEND',
    label: t(REPORT_STATUS_NAMES.READY_TO_SEND),
  },
  { value: 'PENDING', label: t(REPORT_STATUS_NAMES.PENDING) },
  { value: 'SENT', label: t(REPORT_STATUS_NAMES.SENT) },
  { value: 'REJECTED', label: t(REPORT_STATUS_NAMES.REJECTED) },
];

const getReportTypeOptions = (t: TFunction<'translation'>) => [
  { value: DOCUMENT_TYPES.AGGREGATION, label: t('Агрегация') },
  { value: DOCUMENT_TYPES.UTILISATION, label: t('Нанесение') },
  { value: DOCUMENT_TYPES.DROPOUT, label: t('Списание (отбраковка)') },
  { value: DOCUMENT_TYPES.INTRODUCTION, label: t('Ввод в оборот') },
];

export const getFiltersTabs = (t: TFunction<'translation'>) => [
  {
    label: t('Все фильтры'),
    value: 0,
  },
  {
    label: t('Избранные фильтры'),
    value: 1,
  },
  {
    label: t('История фильтров'),
    value: 2,
  },
];

export const getFilters = (
  t: TFunction<'translation'>,
  country: CountryCode,
  filterValues: Record<string, string | string[]> | null
) => {
  const maxDateFrom = filterValues?.dateTo
    ? endOfDay(new Date(filterValues.dateTo as string))
    : endOfDay(new Date());
  const maxDateTo = endOfDay(new Date());
  const minDateTo = filterValues?.dateFrom
    ? endOfDay(new Date(filterValues.dateFrom as string))
    : null;
  return [
    {
      id: 'reportId',
      label: t('Идентификатор отчета'),
      type: FilterType.TEXT,
    },
    {
      id: 'pg',
      label: t('Товарная группа'),
      type: FilterType.SELECT,
      config: {
        options: getPgListByCountry(country, t),
      },
    },
    {
      id: 'type',
      label: t('Тип'),
      type: FilterType.SELECT,
      config: {
        options: getReportTypeOptions(t),
      },
    },
    {
      id: 'creationDate',
      label: t('Дата создания'),
      type: FilterType.DATE_RANGE,
      config: {
        startLabel: t('От'),
        endLabel: t('До'),
        keyFrom: 'creationDateStart',
        keyTo: 'creationDateEnd',
        maxDateFrom,
        maxDateTo,
        minDateTo,
        maxDate: endOfDay(new Date()),
      },
    },
    {
      id: 'status',
      label: t('Статус'),
      type: FilterType.SELECT,
      config: {
        options: getReportStatusFilter(t),
      },
    },
  ];
};

export const getNewColumns = (t: TFunction<'translation'>) => [
  {
    id: 'reportId',
    enableHiding: false,
    accessorKey: 'reportId',
    Cell: ({ row }) => {
      const { type, reportId, status } = row.original;
      const link =
        status === 'DRAFT'
          ? `${type.toLowerCase()}/draft/${reportId}`
          : `documents/view/${type.toLowerCase()}/${reportId}`;
      return (
        <Box className={css['report-id-container']}>
          <Box>
            <ProductGroupIcon pg={row?.original?.pg as ProductGroupTypes} />
          </Box>
          <Box>
            <LinkCell link={link} text={reportId} />
            <Box>
              {t(
                PRODUCT_GROUPS_NAMES[
                  row.original.pg as keyof typeof PRODUCT_GROUPS_NAMES
                ]
              )}
            </Box>
          </Box>
        </Box>
      );
    },
    size: 350,
    header: t('Идентификатор отчета'),
    enableSorting: false,
  },
  {
    id: 'type',
    accessorFn: (row) => DOCUMENT_TYPES_NAMES[row.type],
    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
    header: t('Тип'),
    enableSorting: false,
  },
  {
    id: 'creationDate',
    accessorKey: 'creationDate',
    header: t('Дата создания'),
    enableSorting: false,
  },
  {
    id: 'markingCodesAmount',
    accessorKey: 'markingCodesAmount',
    header: t('Количество КМ'),
    enableSorting: false,
  },
  {
    id: 'isAutoReport',
    accessorFn: (row) =>
      t(row.isAutoReport ? 'Автоматически' : 'Пользователем'),
    Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
    header: t('Отчет создан'),
    enableSorting: false,
  },
  {
    id: 'status',
    accessorKey: 'status',
    Cell: ({ renderedCellValue }) => (
      <Status
        isReportStatus
        label={t(
          REPORT_STATUS_NAMES[
            renderedCellValue as keyof typeof REPORT_STATUS_NAMES
          ]
        )}
        status={renderedCellValue as keyof typeof REPORT_STATUS_NAMES}
      />
    ),
    header: t('Статус'),
    enableSorting: false,
  },
];
