import { put, call, select } from 'redux-saga/effects';
import fp from 'lodash/fp';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { showErrorToast } from '../../components/toastWrapper';
import {
  setItem,
  setList,
  setError,
  setRetryCodeList,
  setPgSettings,
} from '../actions/orders';
import { filters2url, updateUrlParams } from '../../utils/url-utils';
import { history } from '../../store';
import { routes } from '../../routes';
import { CONTENT_TYPES } from '../../helpers/http';

const getProductName = (products, bufferGtin) =>
  products?.find(({ gtin }) => gtin === bufferGtin)?.attributes.product_name ??
  '-';

export function* loadOrderListSaga(action) {
  try {
    const { payload = {} } = action;
    const {
      page = 1,
      filters = [],
      filter = {},
      history,
      pathname = '',
    } = payload;
    yield put(setIsLoading(true));
    const json = yield API.rest.orders.list({ page, ...filter });
    if (json) {
      if (history) {
        const filtersUrl = filters2url(filters);
        const urlString =
          pathname +
          '?' +
          (filtersUrl ? filtersUrl + '&' : '') +
          `page=${page}`;
        yield call(updateUrlParams, { urlString });
      }

      yield put(setList({ ...json }));
      yield put(setIsLoading(false));
    } else {
      yield put(setIsLoading(false));
      yield showErrorToast({ errors: 'Ошибка при выполнении запроса' });
    }
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* loadOrderItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.orders.item(action.payload);

    const inn = yield select((state) => state.currentUser.inn);

    const gtins = json.buffers.map(({ gtin }) => gtin);

    const products = yield API.rest.product.search({ gtins, inn });

    yield put(
      setItem({
        ...json,
        buffers: json.buffers.map((buffer) => ({
          ...buffer,
          productName: getProductName(products, buffer.gtin),
        })),
      })
    );
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(setError(true));
    yield showErrorToast({ errors: err });
  }
}

export function* closeOrderSaga({ payload }) {
  const { orderId } = payload.query;
  try {
    yield put(setIsLoading(true));
    yield API.rest.order.close(payload.query);
    if (payload.buffer) {
      yield call(loadOrderItemSaga, { payload: orderId });
    } else {
      history.push(routes.orders());
    }
    yield put(setIsLoading(false));
    payload.onFinish();
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* sendOrderSaga({ payload }) {
  const { filter } = payload;
  try {
    yield put(setIsLoading(true));
    yield API.rest.orders.patch({ id: payload.id, body: {} });
    yield call(loadOrderListSaga, { payload: filter });
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* printSaga(query, payload, packId) {
  const printQuery = {
    ...query,
    format: payload.format,
    packId,
  };
  let format = 'csv';
  if (payload.format === 'PDF') {
    printQuery.position = payload.position;
    format = 'pdf';
  }
  yield API.rest.codes.print(
    printQuery,
    `Order_${payload.orderId}.${format}`,
    CONTENT_TYPES.json
  );
}

export function* printOrderCodesSaga({ payload }) {
  try {
    const { isRetry } = payload;
    let json;
    if (!isRetry) {
      json = yield API.rest.codes.list(
        fp.pipe(
          fp.pick(['orderId', 'gtin', 'quantity', 'lastPackId', 'pdfStyle'])
        )(payload)
      );
    }
    const packId = isRetry ? payload.lastPackId : json.packId;
    yield call(
      printSaga,
      fp.pipe(fp.pick(['orderId', 'gtin', 'quantity', 'pdfStyle']))(payload),
      payload,
      packId
    );
    yield call(getRetryCodeListSaga, { payload: { orderId: payload.orderId } });
    if (!isRetry) {
      const order = yield API.rest.orders.item(payload.orderId);
      const inn = yield select((state) => state.currentUser.inn);

      const gtins = order.buffers.map(({ gtin }) => gtin);

      const products = yield API.rest.product.search({ gtins, inn });

      yield put(
        setItem({
          ...order,
          buffers: order.buffers.map((buffer) => ({
            ...buffer,
            productName: getProductName(products, buffer.gtin),
          })),
        })
      );
    }
    payload.onFinish();
  } catch (err) {
    yield showErrorToast({ errors: err });
    payload.onFinish();
  }
}

export function* createOrderSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const data = { ...payload };
    const pg = data.productGroup;
    delete data.productGroup;
    yield API.rest.orders.create(data, { pg });
    yield put(setIsLoading(false));
    history.push(routes.orders());
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* getRetryCodeListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.codes.retryList({ ...payload });
    yield put(setRetryCodeList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}

export function* getPgSettingsSaga() {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.settings.list();
    yield put(setPgSettings(json));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
