import { format } from 'date-fns';
import fp from 'lodash/fp';

import { Types, types } from '../types';
import {
  CREATE_METHOD_TYPES_NAMES,
  ORDER_STATUSES,
  RELEASE_METHOD_TYPES_NAMES,
} from '../../pages/orders/constants';
import { MARK_CODE_TYPES_NAMES, PRODUCT_GROUPS_NAMES } from '../../constants';
import { getCountryName } from '../../utils/utils';

import { PAGINATION_DEFAULT } from './constants';

import { PageState } from './index';

type StatusTypes =
  | 'CREATED'
  | 'PENDING'
  | 'READY'
  | 'REJECTED'
  | 'CLOSED'
  | 'EXPIRED';

type ProductGroupType =
  | 'milk'
  | 'pharma'
  | 'alcohol'
  | 'shoes'
  | 'tobacco'
  | 'lp'
  | 'appliances'
  | 'beer'
  | 'water'
  | 'antiseptic';

export type ProductGroupsType = {
  paymentFlow: string;
  pg: string;
  reportsPermit: {
    aggregation: boolean;
    dropout: boolean;
    introduction: boolean;
  };
  licenseRequirements: boolean;
};

type PGSettingsType = null | {
  productGroups: ProductGroupsType[];
};

export type BufferType = {
  availableCodes: number;
  bufferStatus: string;
  cisType: string;
  gtin: string;
  lastPackId?: string;
  leftInBuffer: number;
  quantity: number;
  serialNumberType: string;
  totalPassed: number;
};

export type OrdersData = {
  buffers: BufferType[];
  codesCount: number;
  createDate: string;
  orderId: string;
  orderStatus: StatusTypes;
  pg: ProductGroupType;
  productCount: number;
};

export type CodesRetryListType = {
  cisCount: number;
  count: number;
  gtin: string;
  lastPackId: string;
  packDateTime: string;
  packId: string;
};

export type OrdersState = PageState<OrdersData> & {
  codesRetryList: CodesRetryListType[];
  isError: boolean;
  pgSettings: PGSettingsType;
};

const initialState: OrdersState = {
  list: [],
  isLastPage: false,
  item: {},
  filter: {},
  historyFilters: [],
  savedFilters: [],
  pagination: PAGINATION_DEFAULT,
  codesRetryList: [],
  isError: false,
  pgSettings: null,
};

type OrdersActionType = Types[keyof Pick<
  Types,
  | 'ORDERS_SET_LIST'
  | 'ORDERS_SET_RETRY_LIST'
  | 'ORDERS_SET_ITEM'
  | 'SET_ORDER'
  | 'CLEAR_ORDER'
  | 'CLEAR_ORDERS'
  | 'ORDER_SET_ITEM_ERROR'
  | 'ADD_ORDERS_HISTORY_FILTERS'
  | 'ADD_ORDERS_SAVED_FILTERS'
  | 'SET_PG_SETTINGS'
>];

type OrdersAction = {
  type: OrdersActionType;
  payload: unknown;
};

export const reducer = (state = initialState, action: OrdersAction) => {
  switch (action.type) {
    case types.ORDERS_SET_LIST:
      return {
        ...state,
        isLastPage:
          action.payload.totalPages === state.pagination.page ||
          action.payload.totalPages === 0,
        list: [...state.list, ...parseOrdersList(action.payload)],
      };
    case types.ORDERS_SET_RETRY_LIST:
      return {
        ...state,
        codesRetryList: parseRetryList(action.payload),
      };
    case types.ORDERS_SET_ITEM:
      return {
        ...state,
        item: parseOrderItem(action.payload),
      };
    case types.SET_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_ORDER:
      return {
        ...state,
        item: {},
        codesRetryList: [],
      };
    case types.CLEAR_ORDERS:
      return {
        ...state,
        list: [],
        isLastPage: false,
        item: {},
        filter: {},
        pagination: PAGINATION_DEFAULT,
        codesRetryList: [],
        isError: false,
      };
    case types.ORDER_SET_ITEM_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case types.ADD_ORDERS_HISTORY_FILTERS:
      return {
        ...state,
        historyFilters: action.payload,
      };
    case types.ADD_ORDERS_SAVED_FILTERS:
      return {
        ...state,
        savedFilters: action.payload,
      };
    case types.SET_PG_SETTINGS:
      return {
        ...state,
        pgSettings: action.payload,
      };
    default:
      return state;
  }
};

type OrderInfosType = {
  createDate: string;
  orderId: string;
  orderStatus: StatusTypes;
  pg: ProductGroupType;
  buffers: BufferType[];
};

type OrderListItem = {
  omsId: string;
  orderInfos: OrderInfosType[];
  totalElements: number;
  totalPages: number;
};

const parseOrdersList = (data: OrderListItem[]) => {
  return fp.pipe(
    fp.get('orderInfos'),
    fp.orderBy(['createDate'], ['desc']),
    fp.map((item: OrderInfosType) => ({
      ...item,
      createDate: format(new Date(item.createDate), 'dd.MM.yyyy HH:mm'),
      productCount: item.buffers?.length || 0,
      codesCount: item.buffers
        ? item.buffers.reduce(
            (acc, product) => acc + parseInt(String(product.quantity), 10),
            0
          )
        : 0,
    }))
  )(data);
};

type OrderType = {
  contactPerson: string;
  createDate: string;
  createMethodType: string;
  omsId: string;
  orderId: string;
  pg: ProductGroupType;
  releaseMethodType: string;
  status: StatusTypes;
  buffer: BufferType[];
  factory?: unknown;
};

const parseOrderItem = (order: OrderType) => ({
  ...order,
  pg: PRODUCT_GROUPS_NAMES[order.pg],
  pgValue: order.pg,
  factoryCountry: order?.factory?.country
    ? getCountryName(order.factory.country)
    : '—',
  orderStatus: ORDER_STATUSES[order.status],
  releaseMethodType:
    RELEASE_METHOD_TYPES_NAMES[
      order.releaseMethodType as keyof typeof RELEASE_METHOD_TYPES_NAMES
    ],
  createMethodType:
    CREATE_METHOD_TYPES_NAMES[
      order.createMethodType as keyof typeof CREATE_METHOD_TYPES_NAMES
    ],
  createDate: format(new Date(order.createDate), 'dd.MM.yyyy HH:mm'),
  products: fp.pipe(
    fp.get('buffers'),
    fp.map((v: BufferType) => ({
      ...v,
      cisType:
        MARK_CODE_TYPES_NAMES[v.cisType as keyof typeof MARK_CODE_TYPES_NAMES],
    })),
    fp.orderBy(['gtin'], ['asc'])
  )(order),
});

type RetryListItemType = {
  cisCount: number;
  gtin: string;
  packDateTime: string;
  packId: string;
};

const parseRetryList = (data: { list: RetryListItemType[] }) => {
  return fp.pipe(
    fp.get('list'),
    fp.map((item: RetryListItemType) => ({
      ...item,
      count: item.cisCount,
      lastPackId: item.packId,
      packDateTime: format(new Date(item.packDateTime), 'dd.MM.yyyy HH:mm'),
    }))
  )(data);
};
