import { put, call } from 'redux-saga/effects';

import { setIsLoading } from '../actions/common';
import { API } from '../../api';
import { filters2url, updateUrlParams } from '../../utils/url-utils';
import { setList as setReportList } from '../actions/documents/drafts';
import { showErrorToast } from '../../components/toastWrapper';

export function* loadReportListSaga(action) {
  try {
    const { payload = {} } = action;
    const { page = 1, filters, history, pathname = '', filter = {} } = payload;
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.list({ page, ...filter });
    if (json) {
      if (history) {
        const filtersUrl = filters2url(filters);
        const urlString =
          pathname +
          '?' +
          (filtersUrl ? filtersUrl + '&' : '') +
          `page=${page}`;
        yield call(updateUrlParams, { urlString });
      }
      yield put(setReportList({ ...json }));
      yield put(setIsLoading(false));
    } else {
      yield put(setIsLoading(false));
      yield showErrorToast({ errors: 'Ошибка при выполнении запроса' });
    }
  } catch (err) {
    yield put(setIsLoading(false));
    yield showErrorToast({ errors: err });
  }
}
